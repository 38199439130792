<template>
  <div class="layout-tab">
    <h5>
      <b>How would you like your featured endorsements to be displayed?</b>
    </h5>
    <br />
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-row class="mb-4">
        <b-col lg="6"
          ><div
            class="layout-card"
            @click="layout = 'left'"
            :class="layout == 'left' ? 'border-primary' : ''"
          >
             <div>
               <b-form-radio v-model="layout"
                             :aria-describedby="ariaDescribedby"
                             value="left">
                 Left Aligned.
               </b-form-radio>
               <span class="text-muted description">
                 Featured endorsements will be displayed first before your full
                 list of endorsements.
               </span>
               <b-link v-b-modal.modal-endorsement-example class="example-link">View Example</b-link>
             </div>

            <div class="layout-option">
              <div class="we-endorse"><b>We endorse candidate name.</b></div>
              <b-row>
                <b-col cols="4">
                  <preview-tile></preview-tile>
                </b-col>
                <b-col cols="4">
                  <preview-tile></preview-tile>
                </b-col>
                <b-col cols="4">
                  <preview-tile></preview-tile>
                </b-col>
                <b-col cols="4">
                  <preview-tile></preview-tile>
                </b-col>
                <b-col cols="4">
                  <preview-tile></preview-tile>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="6"
          ><div
            class="layout-card"
            @click="layout = 'center'"
            :class="layout == 'center' ? 'border-primary' : ''"
          >
             <div>
               <b-form-radio v-model="layout"
                             :aria-describedby="ariaDescribedby"
                             value="center">
                 Center Aligned.
               </b-form-radio>
               <span class="text-muted description">
                 Featured endorsements will be displayed first
                 before your full list of endorsements.
               </span>
               <b-link v-b-modal.modal-endorsement-example class="example-link">View Example</b-link>
             </div>

            <div class="layout-option">
              <div class="we-endorse"><b>We endorse candidate name.</b></div>
              <b-row>
                <b-col cols="4"> </b-col>
                <b-col cols="4">
                  <PreviewTile layout="center"></PreviewTile>
                </b-col>
                <b-col cols="4"> </b-col>
                <b-col cols="4">
                  <PreviewTile layout="center"></PreviewTile>
                </b-col>
                <b-col cols="4"> </b-col>
                <b-col cols="4">
                  <PreviewTile layout="center"></PreviewTile>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr />
      <div align="right">
        <b-button
          size="lg"
          :variant="layout ? 'primary' : 'secondary'"
          :disabled="!layout"
          @click="$emit('next')"
          >Next
          <b-icon icon="arrow-right"></b-icon>
        </b-button>
      </div>
    </b-form-group>
  </div>
</template>
<script>
import PreviewTile from "@/components/admin/ManageEndorsements/PreviewTile.vue";
export default {
  props: {
    selectedLayout: {
      type: String
    }
  },
  data() {
    return {
      layout: this.$store.state.candidates.candidate.endorsementLayout === 0 ? "left" : "center"
    };
  },
  components: {
    PreviewTile
  },
  mounted() {
    //console.log(this.$store.state.candidates.candidate.endorsementLayout)
    
  },
  watch: {
    layout(val) {
      var layoutPreference = val == "left" ? 0 : 1;
      this.$store.dispatch("candidates/updateLayoutPreference", {
        layout: layoutPreference,
        userId: this.$store.state.candidates.candidate.userId
      });
    }
  }
};
</script>

<style lang="scss">

  .layout-tab {
    .layout-card {
      background-color: #f5f5f5;
      padding: 20px;
      border-radius: 5px;
      border: 1px solid #f5f5f5;

      &.border-primary {
        border: 1px solid #007bff;
      }

      .description {
        display: block;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .example-link {
        font-size: 14px;
      }

      .layout-option {
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-top: 20px;

        .we-endorse {
          margin-bottom: 10px;
        }

        .col {
          padding-bottom: 5px;
        }
      }
    }

    fieldset {
      margin-bottom: 0;
    }

    .custom-control-label {
      font-weight: 600;
    }

    @media (max-width: 991px) {
      .layout-card {
        margin-bottom: 40px;
      }
    }
  }
</style>
